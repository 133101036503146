<template>
  <div
    class="landing-bg h-screen flex justify-center items-center relative text-white"
  >
    <div class="header-logo">
      <img :src="logoCastres" />
    </div>
    <div class="w-4/5 sm:w-2/3 pb-20">
      <h1
        class="text-6xl sm:text-7xl lg:text-8xl tenor-sans-regular uppercase text-center tracking-widest lg:tracking-normal"
      >
        Luxury Suite Castres
      </h1>
      <h3
        class="text-sm sm:text-base lg:text-xl mt-8 lg:mt-4 font-light text-center uppercase tracking-widest"
      >
        Vivez une escapade romantique unique
      </h3>
    </div>
    <div class="absolute bottom-20 w-full text-sm">
      <div
        class="mx-auto p-4 reservation-btn flex items-center justify-center rounded-xl"
      >
        <div class="flex items-center sm:mr-8">
          <img :src="airBnbIcon" alt="airbnb" class="w-8 h-8 mr-5" />
          <span class="montserrat font-light text-left hidden sm:block"
            >Suite disponible à la location dès maintenant.</span
          >
        </div>
        <a
          class="bg-white text-gray-900 rounded-full px-5 py-2 font-semibold montserrat"
          href="https://app.superhote.com/#/rental/propertyKeyYQY5XJtppM2jDTzMFn7UYftaa"
          target="_blank"
          >Réserver</a
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import airBnbIcon from "@/assets/icons/airbnb.svg";
import logoCastres from "@/assets/logo-castres.png";

export default defineComponent({
  name: "Presentation",
  components: {},
  data() {
    return {
      airBnbIcon,
      logoCastres,
    };
  },
});
</script>

<style lang="scss">
h1 {
  font-size: 8rem;
  margin: 0;
  padding: 0;
  line-height: normal;
}

.header-logo {
  position: absolute;
  top: 20px;
  z-index: 1;
  width: 80px;
}

.landing-bg {
  position: relative;
  background: url("@/assets/illustrations/banner.jpg") no-repeat center
    center/cover;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: brightness(0.4);
    z-index: -1;
  }

  .reservation-btn {
    backdrop-filter: blur(20px);
    background: rgba(25, 25, 25, 0.6);
    width: 400px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
  }

  .reservation-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 75%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    animation: sweep 4s ease-in-out infinite;
  }

  @media (max-width: 640px) {
    .reservation-btn {
      width: 200px;
    }
  }

  @keyframes sweep {
    0% {
      left: -100%;
    }
    100% {
      left: 200%;
    }
  }
}
</style>
