<template>
  <div class="content py-28 lg:w-2/3 m-auto">
    <h2 class="text-3xl uppercase font-semibold mb-10">Luxury Suite, l'évasion Romantique</h2>
    <p class="leading-relaxed font-light">Vivez une escapade romantique unique dans cette suite luxueuse.
      Profitez d'une <strong>balnéo privée</strong> pour vous détendre, d'une <strong>double douche spacieuse</strong>
      et d'une <strong>table de massage</strong> pour des moments de pure complicité.
      Le grand lit (160x200), au cœur d'une ambiance sombre et cosy, vous promet des nuits paisibles et mémorables.
      <strong>Idéal pour une évasion en couple</strong>.
      Réservez dès maintenant !
    </p>
    <a class="text-base text-center text-white bg-primary rounded-full px-5 py-3 font-semibold montserrat mx-auto block w-60 mt-16" href="https://app.superhote.com/#/rental/propertyKeyYQY5XJtppM2jDTzMFn7UYftaa" target="_blank">Réserver maintenant</a>
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";

import logo from "@/assets/logo.png";

export default defineComponent({
  name: "Introduction",
  setup() {
    return {logo};
  },
});
</script>

<style lang="scss"></style>
